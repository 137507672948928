import {
  Button,
  CardPayment,
  Header,
  Modal,
  ModalSuccess,
  PageWrapper,
  Text,
} from 'components';
import { TEXT_STRING, queryKeys } from '../../constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { checkValidAmount, getPendingPayment, payMoney } from 'api';
import { EPaymentMethod, TMoneyPaymentByCardBody } from 'types';
import { useToast } from 'hooks';
import { useChatStore } from 'store';
import MethodPayment from 'components/PaymentMethod/PaymentMethod';

enum EPaymentStep {
  CHOOSE_METHOD = 1,
  PAYMENT = 2,
}

const paymentStr = TEXT_STRING.PAYMENT;
const commonStr = TEXT_STRING.COMMON;
const successContent = {
  [EPaymentMethod.CARD]: paymentStr.PAYMENT_SUCCESS,
  [EPaymentMethod.CASH]: paymentStr.PAYMENT_CASH_SUCCESS,
  [EPaymentMethod.POINT]: paymentStr.POINT_PAYMENT_SUCCESSFULLY,
};

const Payment = () => {
  // Hooks
  const { id: paymentId } = useParams();
  const navigate = useNavigate();
  const { toastError } = useToast();
  const { setShowReview } = useChatStore();

  // States
  const [paymentMethod, setPaymentMethod] = useState<EPaymentMethod>(
    EPaymentMethod.CASH
  );
  const [stepPayment, setStepPayment] = useState<EPaymentStep>();
  const [open, setOpen] = useState<boolean>(false);

  // Query
  const { data, isLoading, isRefetching, isError, error } = useQuery({
    queryKey: [queryKeys.PAYMENT, paymentId],
    queryFn: () => getPendingPayment(+(paymentId || '')),
    enabled: !!paymentId,
    retry: 0,
  });

  // Mutation
  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: (body: TMoneyPaymentByCardBody) => {
      return payMoney(body);
    },
  });

  const { mutateAsync: checkCardAmount, isPending: checking } = useMutation({
    mutationFn: () => {
      return checkValidAmount(+(paymentId || ''));
    },
    onSuccess(data) {
      data && setStepPayment(EPaymentStep.PAYMENT);
      setOpen(!data);
    },
  });

  // Memo, callback
  const pricePayment = useMemo(() => {
    if (paymentMethod === EPaymentMethod.CARD) {
      return +data?.amount + +data?.fee;
    } else {
      return +data?.amount;
    }
  }, [data?.amount, data?.fee, paymentMethod]);

  const allowPaymentMethod: EPaymentMethod[] = useMemo(() => {
    return data?.allowPaymentMethod;
  }, [data?.allowPaymentMethod]);

  const handlePayment = useCallback(
    async (token?: string) => {
      if (paymentId && !!paymentMethod && !isPending) {
        await mutateAsync({ id: +paymentId, token, type: paymentMethod });
      }
    },
    [paymentId, isPending, mutateAsync, paymentMethod]
  );

  const handleMethodPayment = useCallback(async () => {
    try {
      if ([EPaymentMethod.CASH, EPaymentMethod.POINT].includes(paymentMethod)) {
        await handlePayment();
      } else {
        await checkCardAmount();
      }
    } catch (error) {
      toastError(error as Error);
    }
  }, [paymentMethod, handlePayment, checkCardAmount, toastError]);

  const onConfirmSuccess = useCallback(() => {
    navigate(-1);
    setShowReview(true);
  }, [navigate, setShowReview]);

  const handleBack = useCallback(() => {
    if (data?.isPaymentRequest || stepPayment === EPaymentStep.CHOOSE_METHOD)
      navigate(-1);
    setStepPayment(EPaymentStep.CHOOSE_METHOD);
  }, [data?.isPaymentRequest, navigate, stepPayment]);

  useEffect(() => {
    if (data?.isPaymentRequest) {
      setPaymentMethod(EPaymentMethod.CARD);
      setStepPayment(EPaymentStep.PAYMENT);
    } else {
      setStepPayment(EPaymentStep.CHOOSE_METHOD);
    }
  }, [data?.isPaymentRequest]);

  return (
    <div className="h-full overflow-y-auto">
      <Header
        title={<Text bold>{TEXT_STRING.CHAT.RELEASE_TECO_GIRL}</Text>}
        onBack={handleBack}
        disabled={isPending}
      />
      <PageWrapper loading={isLoading || isRefetching} height="h-auto">
        {isError ? (
          <Text center className="py-10" textColor="text-error">
            {error?.message || TEXT_STRING.COMMON.SOMETHING_WENT_WRONG}
          </Text>
        ) : (
          <>
            {stepPayment === EPaymentStep.CHOOSE_METHOD && (
              <MethodPayment
                onChooseMethod={(method) =>
                  setPaymentMethod(method)
                }
                paymentMethod={paymentMethod}
                onSubmit={handleMethodPayment}
                price={pricePayment}
                allowPaymentMethod={allowPaymentMethod}
                loading={isPending || checking}
              />
            )}
            {stepPayment === EPaymentStep.PAYMENT && (
              <CardPayment
                title={
                  data?.isPaymentRequest ? paymentStr.ADVANCE_PAYMENT : ' '
                }
                price={pricePayment}
                onPay={handlePayment}
                paying={isPending}
              />
            )}
            <ModalSuccess open={isSuccess} onConfirm={onConfirmSuccess}>
              <Text className="mt-6" center>
                {successContent[paymentMethod]}
              </Text>
            </ModalSuccess>
            <Modal
              open={open}
              className="p-16px text-center mx-5 bg-white w-3/4"
              onClose={() => {}}
            >
              <Text className="py-6" center>
                {paymentStr.WAIT_MESSAGE}
              </Text>
              <Button className="py-5" onClick={onConfirmSuccess} block>
                {commonStr.CLOSE}
              </Button>
            </Modal>
          </>
        )}
      </PageWrapper>
    </div>
  );
};

export default Payment;
