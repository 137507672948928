import {
  BankTransferInformation,
  CreditCard,
  Header,
  Tabs,
  TecoAsset,
  Text,
} from 'components';
import { useEffect, useMemo, useState } from 'react';
import { EPaymentType, ETransferStep, TBankDetail, TListResult } from 'types';
import { queryKeys, storageKeys, TEXT_STRING } from '../../../constants';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getPendingRequests } from 'api';

const str = TEXT_STRING.POINTS;

function BuyPoints() {
  const queryClient = useQueryClient();
  const [bankStep, setBankStep] = useState<ETransferStep>(ETransferStep.APPLY);
  const [currentTab, setCurrentTab] = useState<EPaymentType>(
    (localStorage.getItem(storageKeys.POINT_TAB) as EPaymentType) ||
      EPaymentType.BANK
  );

  const { data, isFetching } = useQuery<TListResult<TBankDetail>>({
    queryKey: [queryKeys.PENDING_REQUEST_POINT, currentTab],
    queryFn: () =>
      getPendingRequests({
        currentPage: 1,
        pageSize: 1,
      }),
    enabled: currentTab === EPaymentType.BANK,
  });

  const pendingItem = useMemo(() => data?.data?.[0], [data?.data]);

  const showTab = useMemo(
    () => bankStep === ETransferStep.APPLY || !!pendingItem,
    [bankStep, pendingItem]
  );

  useEffect(() => {
    queryClient.refetchQueries({ queryKey: [queryKeys.POINTS] });
  }, [queryClient]);

  return (
    <>
      <Header title={str.TITLE_OF_BUY_POINT} />
      <div className="pt-8 px-4 pb-20 flex flex-col h-full overflow-y-auto">
        {showTab && (
          <div className="sticky z-1 flex flex-col gap-7">
            <TecoAsset />
            <Tabs
              tabs={[
                { id: EPaymentType.BANK, title: str.BANK_TRANSFER },
                { id: EPaymentType.CARD, title: str.CREDIT_CARD },
              ]}
              textSize="text-base"
              currentTab={currentTab}
              onChangeTab={(tab) => setCurrentTab(tab as EPaymentType)}
            />
          </div>
        )}

        {currentTab === EPaymentType.BANK ? (
          <BankTransferInformation
            className={showTab ? 'mt-8' : ''}
            currentStep={bankStep}
            setCurrentStep={setBankStep}
            bankTransferDetail={pendingItem}
            isLoading={isFetching}
          />
        ) : (
          // TODO check bug buy point failed
          <div className="w-full py-16">
            <Text center bold fontSize={16} textColor="text-neutral-600">
              メンテナンス中
            </Text>
          </div>
          // <CreditCard />
        )}
      </div>
    </>
  );
}

export default BuyPoints;
