import {
  Button,
  CardPayment,
  DraggableModal,
  IconButton,
  ListPoints,
  Modal,
  ModalConfirm,
  Text,
} from 'components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { paymentPoints } from 'api';
import { useCallback, useState } from 'react';
import { queryKeys, routes, TEXT_STRING } from '../../../../constants';
import { TListPointsResponse } from 'types';
import { formatAmountNumber } from 'utils';
import { useNavigate } from 'react-router-dom';
import { isAxiosError } from 'axios';

const str = TEXT_STRING.POINTS;
const commonStr = TEXT_STRING.COMMON;

function CreditCard() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [paymentModal, setPaymentModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [currentPointData, setCurrentPointData] =
    useState<TListPointsResponse>();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ token, pointId }: { token: string; pointId: number }) => {
      return paymentPoints({ token, pointId });
    },
    onSuccess: () => {
      setPaymentModal(false);
      setSuccessModal(true);
      queryClient.refetchQueries({ queryKey: [queryKeys.POINTS] });
    },
    onError: (error) => {
      if (isAxiosError(error) && error.response?.data.channelId) {
        navigate(
          routes.CONVERSATION.replace(':id', error.response?.data.channelId)
        );
      }
    },
  });

  const handleConfirmPoints = useCallback(
    (pointData: TListPointsResponse) => {
      setConfirmModal(true);
      setCurrentPointData(pointData);
    },
    [setConfirmModal, setCurrentPointData]
  );

  const handlePayment = useCallback(
    async (token: string) => {
      await mutateAsync({ token, pointId: currentPointData?.id || 0 });
    },
    [currentPointData?.id, mutateAsync]
  );

  return (
    <>
      <ListPoints
        onClick={(point: TListPointsResponse) => handleConfirmPoints(point)}
      />
      <ModalConfirm
        text={{
          title: str.PURCHASE_CONFIRMATION,
          content: str.CONTENT_OF_PURCHASE_POINTS.replace(
            '$price',
            `${formatAmountNumber(currentPointData?.price || 0)}`
          ).replace(
            '$amount',
            `${formatAmountNumber(
              currentPointData?.amount || 0,
              commonStr.POINT_UNIT
            )}`
          ),
          buttonConfirm: str.BUY_NOW,
        }}
        open={confirmModal}
        onClose={() => setConfirmModal(false)}
        onConfirm={() => {
          setConfirmModal(false);
          setPaymentModal(true);
        }}
      ></ModalConfirm>

      <DraggableModal
        open={paymentModal}
        onClose={() => setPaymentModal(false)}
        title={commonStr.CREDIT_CARD_REGISTRATION}
      >
        {paymentModal ? (
          <CardPayment
            onPay={handlePayment}
            paying={isPending}
            price={currentPointData?.price}
            title=""
          ></CardPayment>
        ) : (
          <div></div>
        )}
      </DraggableModal>

      <Modal open={successModal} onClose={() => setSuccessModal(false)}>
        <div className="bg-white p-5">
          <div className="flex justify-end">
            <IconButton
              icon={'close'}
              onClick={() => setSuccessModal(false)}
              transparent
            />
          </div>
          <Text
            fontSize={16}
            fontWeight="font-semibold"
            center
            className="mb-5"
          >
            {str.PURCHASED_TECO_ASSETS}
          </Text>
          <Button
            onClick={() => setSuccessModal(false)}
            className="w-full text-base"
          >
            {commonStr.CLOSE}
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default CreditCard;
