import { BankTransferInformation, Header } from 'components';
import { queryKeys, TEXT_STRING } from '../../../../constants';
import { useState } from 'react';
import { ETransferStep } from 'types';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getBankTransferDetail } from 'api';

function PointHistoryDetail() {
  const [bankStep, setBankStep] = useState<ETransferStep>(ETransferStep.APPLY);
  const { pointId } = useParams();

  const { data: bankTransferDetail, isLoading } = useQuery({
    queryKey: [queryKeys.BANK_TRANSFER_DETAIL, pointId],
    queryFn: () => getBankTransferDetail(pointId || ''),
    enabled: !!pointId,
  });

  return (
    <>
      <Header title={TEXT_STRING.POINTS.TITLE_OF_BUY_POINT} />
      <BankTransferInformation
        currentStep={bankStep}
        setCurrentStep={setBankStep}
        className="py-8 px-5 pb-20"
        bankTransferDetail={bankTransferDetail}
        isLoading={isLoading}
      />
    </>
  );
}

export default PointHistoryDetail;
