import { useEffect, useMemo, useState } from 'react';
import {
  Header,
  Loading,
  PointHistoryTab,
  Tabs,
  TecoAsset,
  Text,
} from 'components';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { getPointHistory } from 'api';
import { EPaymentType, EPointHistoryType } from 'types';
import { useNavigate } from 'react-router-dom';
import {
  PAGE_SIZE,
  queryKeys,
  routes,
  TEXT_STRING,
} from '../../../../constants';

const str = TEXT_STRING.POINTS;

function PointHistory() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentTab, setCurrentTab] = useState<EPaymentType>(EPaymentType.BANK);

  const {
    data: bankData,
    fetchNextPage: fetNextBankPage,
    hasNextPage: hasNextBankPage,
    isLoading: isBankLoading,
  } = useInfiniteQuery({
    queryKey: [queryKeys.BUY_POINTS_HISTORY, EPaymentType.BANK],
    queryFn: ({ pageParam }) =>
      getPointHistory(
        pageParam,
        PAGE_SIZE,
        false,
        EPaymentType.BANK,
        EPointHistoryType.ADDED
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, currentPage) => {
      return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
    },
  });

  const {
    data: creditData,
    fetchNextPage: fetNextCreditPage,
    hasNextPage: hasNextCreditPage,
    isLoading: isCreditLoading,
  } = useInfiniteQuery({
    queryKey: [queryKeys.BUY_POINTS_HISTORY, EPaymentType.CARD],
    queryFn: ({ pageParam }) =>
      getPointHistory(
        pageParam,
        PAGE_SIZE,
        false,
        EPaymentType.CARD,
        EPointHistoryType.ADDED
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, currentPage) => {
      return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
    },
  });

  const children = useMemo(
    () => (
      <div className="flex flex-col gap-8 pb-3">
        <TecoAsset />
        <Tabs
          className="mx-0.5"
          tabs={[
            {
              id: EPaymentType.BANK,
              title: str.BANK_TRANSFER,
            },
            {
              id: EPaymentType.CARD,
              title: str.CREDIT_CARD,
            },
          ]}
          textSize="text-base"
          currentTab={currentTab}
          onChangeTab={(tab) => {
            setCurrentTab(tab as EPaymentType);
          }}
        />
      </div>
    ),
    [currentTab]
  );

  const bankHistory = useMemo(
    () => bankData?.pages.map((item) => item.data).flat() ?? [],
    [bankData?.pages]
  );

  const creditHistory = useMemo(
    () => creditData?.pages.map((item) => item.data).flat() ?? [],
    [creditData?.pages]
  );

  const bankRowCount = useMemo(
    () => bankHistory?.length + +hasNextBankPage,
    [hasNextBankPage, bankHistory?.length]
  );

  const creditRowCount = useMemo(
    () => creditHistory?.length + +hasNextCreditPage,
    [hasNextCreditPage, creditHistory?.length]
  );

  const handleClickOnPointItem = (pointId: number, isBankType: boolean) => {
    if (!isBankType) return;
    navigate(routes.POINT_HISTORY_DETAIL.replace(':pointId', `${pointId}`));
  };

  useEffect(() => {
    queryClient.refetchQueries({ queryKey: [queryKeys.POINTS] });
  }, [queryClient]);

  return (
    <>
      <Header title={str.POINT_HISTORY_TITLE} />
      <div className="pt-8 px-4 pb-20 h-full flex flex-col">
        {isBankLoading || isCreditLoading ? (
          <div className="flex justify-center py-9">
            <Loading />
          </div>
        ) : !bankHistory.length && !creditHistory.length ? (
          <Text bold center className="py-16">
            {TEXT_STRING.COMMON.NO_DATA}
          </Text>
        ) : (
          <div className="flex-1">
            {currentTab === EPaymentType.BANK ? (
              <PointHistoryTab
                children={children}
                data={bankHistory}
                fetchMore={fetNextBankPage}
                hasMore={hasNextBankPage}
                dataLength={bankHistory.length}
                rowCount={bankRowCount}
                onClickPointItem={handleClickOnPointItem}
              />
            ) : (
              <PointHistoryTab
                children={children}
                data={creditHistory}
                fetchMore={fetNextCreditPage}
                hasMore={hasNextCreditPage}
                dataLength={creditHistory.length}
                rowCount={creditRowCount}
                onClickPointItem={handleClickOnPointItem}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default PointHistory;
