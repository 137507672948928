import { PurchaseHistoryItem } from 'components/Points';
import VirtualizedList from 'components/VirtualizedList';
import { CellMeasurerCache } from 'react-virtualized';
import { EPaymentType, TPointHistoryResponse } from 'types';
import { ReactElement } from 'react';

type Props = {
  children: ReactElement;
  data: TPointHistoryResponse[];
  fetchMore: () => void;
  hasMore: boolean;
  dataLength: number;
  rowCount: number;
  onClickPointItem: (id: number, isBankType: boolean) => void;
};

function PointHistoryTab({
  data,
  fetchMore,
  hasMore,
  dataLength,
  rowCount,
  onClickPointItem,
  children,
}: Props) {
  return (
    <VirtualizedList
      cache={
        // TODO
        new CellMeasurerCache({
          defaultHeight: 50,
          fixedWidth: true,
        })
      }
      overscanRowCount={10}
      fetchMore={fetchMore}
      hasMore={hasMore}
      dataLength={dataLength}
      rowCount={rowCount}
      RowItem={({ index, style, registerChild }) => {
        const pointItem = data[index];
        const isBankType = pointItem?.paymentType === EPaymentType.BANK;
        return (
          <div ref={registerChild} style={style}>
            {index === 0 && children}
            <div className="py-1">
              {pointItem && (
                <PurchaseHistoryItem
                  className={isBankType ? 'cursor-pointer' : ''}
                  data={pointItem}
                  onClick={() => onClickPointItem(pointItem?.id, isBankType)}
                />
              )}
            </div>
          </div>
        );
      }}
    />
  );
}

export default PointHistoryTab;
