import axios from 'axios';
import {
  TListPointsResponse,
  TListResult,
  TPaymentPointsResponse,
  TPointHistoryResponse,
  TBankDetail,
  TRequestPointsDTO,
  TPointsPending,
  TPaymentRequestDTO,
  TPoints,
  TPagination,
  TBodyUpdateRequestPoints,
} from 'types';

const getPoints = async (): Promise<TPoints> => {
  return await axios.get('points').then((res) => res.data);
};

const getListPoints = async (): Promise<TListPointsResponse[]> => {
  return await axios.get('points/options').then((res) => {
    return res.data;
  });
};

const getPointHistory = async (
  currentPage: number,
  pageSize: number,
  allPayment: boolean,
  paymentType?: string,
  historyType?: string
): Promise<TListResult<TPointHistoryResponse>> => {
  return await axios
    .get('points/history', {
      params: { currentPage, pageSize, paymentType, historyType, allPayment },
    })
    .then((res) => res.data);
};

const requestPoints = async (
  body: TRequestPointsDTO
): Promise<{ historyId: number }> => {
  return await axios.post('points/request', body).then((res) => res.data);
};

const updateRequestPoints = async (body: TBodyUpdateRequestPoints) => {
  return await axios.patch('points/request', body).then((res) => res.data);
};

const paymentPoints = async (body: {
  token: string;
  pointId: number;
}): Promise<TPaymentPointsResponse> => {
  return await axios.post('points/payment', body).then((res) => res.data);
};

const getBankTransferDetail = async (
  historyId: string
): Promise<TBankDetail> => {
  return await axios
    .get(`points/bank-detail/${historyId}`)
    .then((res) => res.data);
};

const getPointsPending = async (historyId: number): Promise<TPointsPending> => {
  return await axios
    .get(`points/pending?historyId=${historyId}`)
    .then((res) => res.data);
};

const paymentRequest = async (
  body: TPaymentRequestDTO
): Promise<TPaymentPointsResponse> => {
  return await axios
    .post('points/payment/request', body)
    .then((res) => res.data);
};

const getPendingRequests = async ({
  currentPage,
  pageSize,
}: TPagination): Promise<TListResult<TBankDetail>> => {
  return await axios
    .get(`points/male/pending-request`, {
      params: { currentPage, pageSize },
    })
    .then((res) => res.data);
};

export {
  getListPoints,
  getPointHistory,
  requestPoints,
  paymentPoints,
  getBankTransferDetail,
  getPointsPending,
  paymentRequest,
  getPoints,
  getPendingRequests,
  updateRequestPoints,
};
