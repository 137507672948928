enum EOrderStep {
  ORDER_METHOD = 1,
  CALL_WOMAN = 2,
  SPECIFY_STAR = 3,
  ORDER_DETAIL = 4,
  SUMMARY_ORDER = 5,
}

enum EPost {
  RECOMMEND = 'recommend',
  FOLLOWED = 'followed',
}

enum EFilterFemale {
  OPTIONS = 'options',
  RANKS = 'ranks',
  SCHEDULES = 'schedules',
  DISCOUNT = 'discount',
  ACTIVITY_AREAS = 'activityAreas',
  STAR = 'starIds',
}

enum ESearchTab {
  SCHEDULES = 'work-today',
  LIST_EMPLOYEE = 'list-employee',
  LIST_DISCOUNT = 'list-discount',
}

enum ESearchTabDiscount {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

enum ESettingNoti {
  GROUP_CHAT = 'groupChat',
  STAFF_MESSAGE = 'managementChat',
  GIRL_MESSAGE = 'privateChat',
  CONFLUENCE = 'joinedChat',
  GAME_SELECTION = 'gameSelection',
  PROFILE_REQUEST = 'profileRequest',
}

enum EActionChat {
  SELECT_GAME = 1,
  LIST_GAME = 2,
  ORDER_METHOD = 3,
  ADD_GIRL = 4,
  RELEASE_GIRL = 5,
}

enum EGameType {
  COMMON = 'COMMON',
  COMMON_SPECIAL = 'COMMON_SPECIAL',
  SINGLE_SPECIAL = 'SINGLE_SPECIAL',
  GROUP_SPECIAL = 'GROUP_SPECIAL',
  GROUP_KING = 'GROUP_KING',
}

enum EUserProvider {
  MALE_APPLICATION = 'MALE_APPLICATION',
  FEMALE_APPLICATION = 'FEMALE_APPLICATION',
  MANAGEMENT_APPLICATION = 'MANAGEMENT_APPLICATION',
}

enum ESystemMobile {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  OTHER = 'OTHER',
}

enum ENotificationType {
  PRIVATE = 'PRIVATE',
  MANAGEMENT = 'MANAGEMENT',
  PROJECT = 'PROJECT',
  SHIFT_MANAGEMENT = 'SHIFT_MANAGEMENT',
  NEW_POST = 'NEW_POST',
  NEW_IMAGE_REQUEST = 'NEW_IMAGE_REQUEST',
}

enum EChannelType {
  PRIVATE = 'PRIVATE',
  GROUP = 'GROUP',
  MALE_MANAGEMENT = 'MALE_MANAGEMENT',
  MALE_SYSTEM_MANAGEMENT = 'MALE_SYSTEM_MANAGEMENT',
  FEMALE_MANAGEMENT = 'FEMALE_MANAGEMENT',
  PROJECT = 'PROJECT',
  SHIFT_MANAGEMENT = 'SHIFT_MANAGEMENT',
}

enum EPaymentMethod {
  CASH = 'CASH',
  CARD = 'CARD',
  POINT = 'POINT',
}

enum EAppInfo {
  ABOUT_APP = 'about-app',
  COMMUNICATION_GUIDE = 'communication-guide',
  TERMS_OF_SERVICE = 'terms-of-service',
  PRIVACY_POLICY = 'privacy-policy',
  LEGAL_COMPLIANCE_POLICY = 'legal-compliance-policy',
}

enum EProfileInfo {
  AGE = 'age',
  INCOME = 'income',
}

enum EStatusProfile {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
}

enum EFieldFormTravel {
  MALE_COUNT = 'maleCount',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  FEMALE_COUNT = 'peopleCount',
  NOMINATED_GIRL = 'nominatedGirl',
}

enum EProjectType {
  BASIC = 'BASIC',
  TOUR = 'TOUR',
}

enum EOrderTravelStep {
  ORDER_PRICE = 1,
  ORDER_GIRL = 2,
  SUMMARY_ORDER = 3,
}

enum EStar {
  EMPTY = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
}

enum EGiftPaymentStep {
  GIFTS_PREDICT = 1,
  CHOOSE_METHOD = 2,
  CHOOSE_CARD = 3,
}

enum EPaymentType {
  BANK = 'BANK',
  CARD = 'CARD',
  CASH = 'CASH',
  PREPAID = 'PREPAID',
  POINT = 'POINT',
}

enum EPointRequestStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

enum EPointHistoryType {
  ADDED = 'ADDED',
  SUBTRACTED = 'SUBTRACTED',
}

enum EPointPaymentStep {
  PROCESSING_DETAIL = 1,
  PAYMENT = 2,
}

enum ETransferStep {
  APPLY = 1,
  TRANSFER = 2,
  TECO_FEE = 3,
}

export {
  EOrderStep,
  EPost,
  EFilterFemale,
  EActionChat,
  ESearchTab,
  EGameType,
  EUserProvider,
  ESettingNoti,
  ESystemMobile,
  ENotificationType,
  EPaymentMethod,
  EChannelType,
  ESearchTabDiscount,
  EAppInfo,
  EProfileInfo,
  EStatusProfile,
  EFieldFormTravel,
  EProjectType,
  EOrderTravelStep,
  EStar,
  EGiftPaymentStep,
  EPaymentType,
  EPointRequestStatus,
  EPointHistoryType,
  EPointPaymentStep,
  ETransferStep,
};
