import { usePubNub } from 'pubnub-react';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import ChatHeader from './ChatHeader';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  DEBOUNCE_SEND_MESSAGE_ACTIVE,
  MESSAGE_KEY,
  MESSAGE_SIZE,
  TEXT_STRING,
  SIGNAL_EVENT,
  queryKeys,
  routes,
} from '../../constants';
import { FileEvent, MessageEvent, SignalEvent } from 'pubnub';
import { useChatStore, useMessageStore, useUserStore } from 'store';
import {
  EChannelType,
  EGiftPaymentStep,
  EPointPaymentStep,
  EUserProvider,
  TBodyPushNotification,
  TChannel,
  TChannelDetail,
  TGiftMessage,
  TMember,
  TMessageDetail,
  TSendMessage,
} from 'types';
import {
  pushNotificationMessage,
  updateChannelActive,
  updateClientChannelActive,
  updateClientSeenTime,
  updateSeenTime,
} from 'api';
import { useNavigate } from 'react-router-dom';
import { ConversationContext } from 'contexts';
import { FloatButton, GiftView } from 'components';
import { ChatContext } from './context';
import PaymentRequest from './PaymentRequest';

type TMessage = {
  channel: string;
  message: TMessageDetail;
  timetoken: string | number;
  messageType?: string | number | undefined;
  uuid?: string | undefined;
  error?: string | undefined;
};

type Props = {
  channel: string;
  users?: TMember[];
  onCreateChannel?: () => Promise<TChannel | undefined>;
  channelData?: TChannelDetail;
  onBlur?: () => void;
  onFocus?: () => void;
  action?: ReactElement;
  refetch: () => void;
  readonly?: boolean;
};

const Chat = ({
  channel,
  channelData,
  users = [],
  onCreateChannel,
  onBlur,
  onFocus,
  action,
  refetch,
  readonly = false,
}: Props) => {
  // Hooks
  const navigate = useNavigate();
  const pubnub = usePubNub();
  const { user, isAuthenticated, clientId } = useUserStore();
  const { resetUnreadCount } = useMessageStore();
  const timeoutRef = React.useRef<NodeJS.Timeout>();
  const timeoutSeenRef = React.useRef<NodeJS.Timeout>();
  const queryClient = useQueryClient();
  const { showReview, setShowReview } = useChatStore();

  // Context
  const { refetchLeverage } = useContext(ConversationContext);

  // States
  const [channels] = useState<string[]>([channel]);
  const [messages, setMessages] = useState<TMessage[]>([]);
  const [showTyping, setShowTyping] = useState<boolean>(false);
  const [giftView, setGiftView] = useState<TGiftMessage | undefined>();
  const [, setGiftViewQueue] = useState<TGiftMessage[]>([]);
  const [historyPaymentId, setHistoryPaymentId] = useState<number>();
  const [stepPaymentGift, setStepPaymentGift] =
    React.useState<EGiftPaymentStep>();
  const [pointHistoryId, setPointHistoryId] = useState<number>();
  const [paymentStep, setPaymentStep] = useState<EPointPaymentStep>();

  // Query
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.MESSAGE_LIST, channel],
    queryFn: async () => {
      return pubnub
        .fetchMessages({
          channels: [channel],
          count: MESSAGE_SIZE,
          includeMessageType: true,
          includeUUID: true,
          includeMeta: true,
          includeMessageActions: true,
          start: messages[messages.length - 1]?.timetoken,
        })
        .then((res) => res.channels[channel] || []);
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) => {
      return lastPage.length >= MESSAGE_SIZE ? lastPageParam + 1 : undefined;
    },
    gcTime: 0,
    enabled: !!pubnub,
  });

  // Mutation
  const { mutate } = useMutation({
    mutationFn: () => {
      return isAuthenticated
        ? updateChannelActive(channel)
        : updateClientChannelActive(channel, clientId || '');
    },
    retry: 0,
  });

  const { mutateAsync: mutateSeen } = useMutation({
    mutationFn: () => {
      return isAuthenticated
        ? updateSeenTime(channel)
        : updateClientSeenTime(channel, clientId || '');
    },
    retry: 0,
  });

  const { mutate: mutatePushNotification } = useMutation({
    mutationFn: (body: TBodyPushNotification) => {
      return pushNotificationMessage(body);
    },
    retry: 0,
  });

  // Memo, callbacks
  const femaleId = React.useMemo(
    () =>
      users.find(
        (user) =>
          user.provider === EUserProvider.FEMALE_APPLICATION &&
          channelData?.type === EChannelType.PRIVATE
      )?.id,
    [channelData?.type, users]
  );

  const refetchCurrentUser = React.useCallback(() => {
    queryClient.refetchQueries({ queryKey: [queryKeys.CURRENT_USER] });
  }, [queryClient]);

  const mapMessage = React.useCallback(
    (message: TMessageDetail) => {
      let url;
      if (message.message.file) {
        url = pubnub.getFileUrl({
          channel: channel,
          id: message.message.file.id,
          name: message.message.file.name,
        });
      }
      return { ...message, url: url };
    },
    [pubnub, channel]
  );

  const handleSignal = React.useCallback(
    (params: SignalEvent) => {
      if (![clientId, user?.uuid].includes(params.publisher)) {
        if (params.message === SIGNAL_EVENT.TYPING_ON) {
          setShowTyping(true);
        } else if (params.message === SIGNAL_EVENT.TYPING_OFF) {
          setShowTyping(false);
        } else if (params.message === SIGNAL_EVENT.PROJECT_LEVERAGE) {
          refetchLeverage && refetchLeverage();
          // Refetch total
          queryClient.refetchQueries({
            queryKey: [queryKeys.TOTAL_LEVERAGE_APPROVED, channel],
          });
        }
      }
    },
    [setShowTyping, user, clientId, refetchLeverage, queryClient, channel]
  );

  const handleViewGift = React.useCallback(
    async (gift: TGiftMessage) => {
      if (giftView) {
        setGiftViewQueue((prev) => [...prev, gift]);
      } else setGiftView(gift);
    },
    [giftView]
  );

  const handleSeenTime = React.useCallback(() => {
    // send seen time
    if (timeoutSeenRef.current) return;
    timeoutSeenRef.current = setTimeout(() => {
      mutateSeen();
      resetUnreadCount(channel);
      clearTimeout(timeoutSeenRef.current);
      timeoutSeenRef.current = undefined;
    }, DEBOUNCE_SEND_MESSAGE_ACTIVE);
  }, [channel, resetUnreadCount, mutateSeen]);

  const handleMessage = React.useCallback(
    (event: MessageEvent) => {
      if (channel === event.channel) {
        if (event?.message?.gift) {
          handleViewGift(event?.message?.gift);
        }
        handleSeenTime();
        setMessages((messages) => {
          return messages.some(
            (message) => message.timetoken === event.timetoken
          )
            ? messages
            : [
                mapMessage({
                  channel: event.channel,
                  message: event.message,
                  timetoken: event.timetoken,
                  // messageType: event.messageType,
                  uuid: event.publisher,
                }),
                ...messages,
              ];
        });

        if (
          [MESSAGE_KEY.ADMIN_RELEASE_PAYMENT].includes(
            event?.message?.data?.key
          )
        ) {
          refetchCurrentUser();
        }

        if (
          [MESSAGE_KEY.GIRL_MEETING, MESSAGE_KEY.GIRL_CANCEL_MEETING].includes(
            event?.message?.data?.key
          )
        ) {
          refetch();
        }
      }
    },
    [
      channel,
      handleSeenTime,
      mapMessage,
      refetch,
      refetchCurrentUser,
      handleViewGift,
    ]
  );

  const handleFile = React.useCallback(
    (event: FileEvent) => {
      handleSeenTime();
      setMessages((messages) => [
        mapMessage({
          channel: event.channel,
          message: { text: event.message.text, file: event.file },
          timetoken: event.timetoken,
          // messageType: event.messageType,
          uuid: event.publisher,
        }),
        ...messages,
      ]);
    },
    [handleSeenTime, mapMessage]
  );

  const sendMessage = React.useCallback(
    async (payload: TSendMessage) => {
      const { message, file, gift } = payload;
      if (!file && !message.trim() && !gift) return;
      let messageBody = `${message.trim()}\n`;
      if (file) messageBody += TEXT_STRING.MESSAGE.IMAGE;
      if (gift)
        messageBody += TEXT_STRING.COMMON.QUOTE.replace('$value', gift.name);
      const notificationTitle = `${user?.name || ''} ${
        TEXT_STRING.COMMON.RECEIVED_MESSAGE
      }`;
      const messagePayload = {
        text: message,
        pn_gcm: {
          // TODO update notification
          notification: {
            title: notificationTitle,
            body: messageBody,
            sound: 'default',
          },
          data: {
            key: channel,
            type: channelData?.type,
          },
        },
        gift,
      };
      let targetChannel = channel;
      if (onCreateChannel) {
        const newChannel = await onCreateChannel();
        if (newChannel?.id) {
          targetChannel = newChannel?.id;
        }
      }
      if (file) {
        await pubnub.sendFile({
          channel: targetChannel,
          message: messagePayload,
          file: file,
        });
      } else if (message || gift) {
        await pubnub.publish({
          channel: targetChannel,
          message: messagePayload,
        });
      }
      mutatePushNotification({
        channelId: channel,
        channelType: channelData?.type || '',
        message: messageBody,
        title: notificationTitle,
      });
      const channelUsers = users.filter((item) => item.uuid !== user?.uuid);
      if (channelUsers) {
        Promise.all(
          channelUsers.map(({ uuid }) =>
            pubnub.publish({
              channel: uuid,
              message: {
                channel: channel,
                text: messageBody,
              },
            })
          )
        );
      }

      // send channel active
      if (timeoutRef.current) return;
      timeoutRef.current = setTimeout(() => {
        mutate();
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }, DEBOUNCE_SEND_MESSAGE_ACTIVE);
    },
    [
      users,
      user,
      channel,
      channelData?.type,
      onCreateChannel,
      pubnub,
      mutate,
      mutatePushNotification,
    ]
  );

  const onTyping = React.useCallback(
    (status: boolean) => {
      pubnub.signal({
        message: status ? SIGNAL_EVENT.TYPING_ON : SIGNAL_EVENT.TYPING_OFF,
        channel: channel,
      });
    },
    [pubnub, channel]
  );

  const handleRedirectChannel = React.useCallback(
    async (member: TMember) => {
      if (
        ![EChannelType.MALE_MANAGEMENT].includes(
          channelData?.type as EChannelType
        )
      ) {
        if (
          member?.provider === EUserProvider.FEMALE_APPLICATION &&
          member?.id
        ) {
          navigate(routes.DETAIL_FEMALE.replace(':id', `${member?.id}`));
        } else if (
          ![
            EUserProvider.FEMALE_APPLICATION,
            EUserProvider.MALE_APPLICATION,
          ].includes(member?.provider)
        ) {
          navigate(
            routes.CONVERSATION.replace(':id', `${user?.managementChannelId}`)
          );
        }
      }
    },
    [channelData?.type, navigate, user?.managementChannelId]
  );

  // Effects
  useEffect(() => {
    if (data?.pages) {
      const reverseData = [...data.pages[data.pages.length - 1]].reverse();
      setMessages((prev) => [...prev, ...reverseData.map(mapMessage)]);
    }
    return () => {};
  }, [data, mapMessage]);

  useEffect(() => {
    const listenerParams = {
      message: handleMessage,
      signal: handleSignal,
      file: handleFile,
    };

    if (!isLoading) {
      pubnub.addListener(listenerParams);
      pubnub.subscribe({ channels });

      return () => {
        pubnub.unsubscribe({ channels });
        pubnub.removeListener(listenerParams);
      };
    }
  }, [pubnub, channels, handleMessage, handleSignal, handleFile, isLoading]);

  useEffect(() => {
    // Clear timeout update channel active
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        mutate();
      }
    };
  }, [mutate]);

  useEffect(() => {
    resetUnreadCount(channel);

    return () => {};
  }, [channel, resetUnreadCount]);

  useEffect(() => {
    return () => {
      if (showReview) {
        refetchCurrentUser();
        setShowReview(false);
      }
    };
  }, [refetchCurrentUser, setShowReview, showReview]);

  const memberObj = React.useMemo(() => {
    let obj: { [x: string]: TMember } = {};
    if (users.length) {
      users.forEach((user) => {
        obj[user.uuid] = user;
      });
    }
    return obj;
  }, [users]);

  return (
    <ChatContext.Provider
      value={{
        giftView,
        setGiftView: handleViewGift,
        historyPaymentId,
        setHistoryPaymentId,
        setStepPaymentGift,
        stepPaymentGift,
        setPointHistoryId,
        setPaymentStep,
      }}
    >
      <div className="h-full w-full fixed top-0 flex flex-col justify-between overflow-hidden bg-white">
        <ChatHeader
          channelData={channelData}
          users={users}
          onClick={handleRedirectChannel}
        />
        <MessageList
          isLoading={isLoading}
          messages={messages}
          fetchMore={fetchNextPage}
          hasMore={hasNextPage}
          members={memberObj}
          channelData={channelData}
          onClickAvatar={handleRedirectChannel}
        />
        {!readonly && (
          <MessageInput
            sendMessage={sendMessage}
            onTyping={onTyping}
            showTyping={showTyping}
            onBlur={onBlur}
            onFocus={onFocus}
            femaleId={femaleId}
            channelData={channelData}
          />
        )}
        {action}
        {channelData?.type === EChannelType.MALE_MANAGEMENT ? (
          <FloatButton
            className="justify-center"
            content={TEXT_STRING.NAVIGATION.CALL}
            handleClick={() => navigate(routes.CALL)}
          />
        ) : null}
      </div>
      {giftView && (
        <div className="fixed z-50 w-full h-full content-center">
          <GiftView
            name={giftView.name}
            loop={false}
            animations={giftView.animations}
            image={giftView.image}
            onComplete={() => {
              setGiftViewQueue((prev) => {
                setGiftView(prev[0]);
                return prev.slice(1);
              });
            }}
          />
        </div>
      )}

      <PaymentRequest
        paymentStep={paymentStep}
        setPaymentStep={setPaymentStep}
        pointHistoryId={pointHistoryId}
      ></PaymentRequest>
    </ChatContext.Provider>
  );
};

export default Chat;
