import { CSSProperties, memo } from 'react';
import { TPostMedia } from 'types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import VideoPlayer from 'components/VideoPlayer';

type TVideoOptions = {
  isAutoPlayVideo: boolean;
  controls: boolean;
};

type Props = TPostMedia & {
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  onLoad?: () => void;
  videoOptions?: TVideoOptions;
};

const MediaView = ({
  type,
  url,
  className = '',
  style,
  onClick,
  onLoad,
  videoOptions = { controls: false, isAutoPlayVideo: true },
  poster,
  thumbnail,
}: Props) => {
  return (
    <>
      {type.startsWith('image/') ? (
        <LazyLoadImage
          alt=""
          src={url}
          className={`${className} cursor-pointer h-full w-full object-cover`}
          onClick={onClick}
          height={'100%'}
          width={'100%'}
          onLoad={onLoad}
          style={style}
          // effect="blur"
        />
      ) : (
        <VideoPlayer
          url={url}
          className={className}
          isAutoPlayOnView={videoOptions.isAutoPlayVideo}
          isFullscreenOnclick
          controls={videoOptions.controls}
          onLoad={onLoad}
          poster={
            videoOptions.isAutoPlayVideo ? undefined : poster || thumbnail || ''
          }
        />
      )}
    </>
  );
};

export default memo(MediaView);
