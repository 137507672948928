import { useMutation, useQuery } from '@tanstack/react-query';
import { getPointsPending, paymentRequest } from 'api';
import CardPayment from 'components/CardPayment';
import DraggableModal from 'components/DraggableModal';
import ProcessingPriceList from 'components/ProcessingPriceList';
import { queryKeys, TEXT_STRING } from '../../../constants';
import { memo, useCallback } from 'react';
import { useToast } from 'hooks';
import { EPointPaymentStep, TPaymentRequestDTO } from 'types';

type Props = {
  pointHistoryId: number | undefined;
  paymentStep: EPointPaymentStep | undefined;
  setPaymentStep: (step?: EPointPaymentStep) => void;
};

const commonStr = TEXT_STRING.COMMON;

const titleModal = {
  [EPointPaymentStep.PROCESSING_DETAIL]: commonStr.TOTAL_POINTS,
  [EPointPaymentStep.PAYMENT]: commonStr.CREDIT_CARD_REGISTRATION,
};

function PaymentRequest({
  pointHistoryId = 0,
  paymentStep,
  setPaymentStep,
}: Props) {
  const { toastError, toastSuccess } = useToast();

  const { data, isRefetching, isLoading, refetch } = useQuery({
    queryKey: [queryKeys.POINTS_PENDING, pointHistoryId],
    queryFn: () => getPointsPending(pointHistoryId),
    enabled: !!pointHistoryId,
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ token, historyId, pendingIds }: TPaymentRequestDTO) =>
      paymentRequest({ token, historyId, pendingIds }),
  });

  const handlePayment = useCallback(
    async (token: string) => {
      try {
        await mutateAsync({
          token,
          historyId: pointHistoryId,
          pendingIds: [],
        });
        toastSuccess(commonStr.SUCCESS);
      } catch (error) {
        toastError(error as Error);
      } finally {
        refetch();
        setPaymentStep();
      }
    },
    [
      mutateAsync,
      pointHistoryId,
      refetch,
      setPaymentStep,
      toastError,
      toastSuccess,
    ]
  );

  return (
    <DraggableModal
      open={!!paymentStep}
      onClose={() => setPaymentStep()}
      title={titleModal[paymentStep as EPointPaymentStep]}
    >
      {paymentStep === EPointPaymentStep.PROCESSING_DETAIL ? (
        <ProcessingPriceList
          processing={data?.pending}
          isRefetching={isRefetching}
          disabled={data?.isSuccess}
          onNextStep={() => {
            setPaymentStep(EPointPaymentStep.PAYMENT);
          }}
          totalAmount={data?.totalAmount}
          isLoading={isLoading}
        ></ProcessingPriceList>
      ) : null}

      {paymentStep === EPointPaymentStep.PAYMENT ? (
        <CardPayment
          onPay={handlePayment}
          paying={isPending}
          price={data?.remainingAmount}
          title=""
        ></CardPayment>
      ) : null}
    </DraggableModal>
  );
}

export default memo(PaymentRequest);
