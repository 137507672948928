import { useUserStore } from 'store';
import { firebaseAuth, getMessagingToken } from 'configs';
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
} from 'firebase/auth';
import {
  checkIdentify,
  signup as signupApi,
  login as loginApi,
  logout as logoutApi,
} from 'api';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../constants';
import { clearStorage } from 'utils';
import { TReqRegister } from 'types';

function useAuth() {
  const { setIsAuthenticated, isAuthenticated, subscriptionId, clientId } =
    useUserStore();
  const navigate = useNavigate();
  const location = useLocation();

  const verifyPhone = async (phone: string, isValid: boolean) => {
    const isIdentify = await checkIdentify(phone);
    if (isIdentify.isValidUser !== isValid || isIdentify.isDisabledUser) {
      throw new Error(isIdentify.message);
    }
  };

  const recaptchaVerifier = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        firebaseAuth,
        'recaptcha-container',
        {
          size: 'invisible',
          callback: () => {},
          'expired-callback': () => {},
        }
      );
    }
  };

  const sendOTP = async (phone: string) => {
    recaptchaVerifier();

    const appVerifier = window.recaptchaVerifier;

    const confirmationResult = await signInWithPhoneNumber(
      firebaseAuth,
      phone,
      appVerifier
    );
    window.confirmationResult = confirmationResult;
  };

  const login = async (token: string) => {
    const fcmToken = await getMessagingToken();
    await loginApi(token, fcmToken, subscriptionId);
    setIsAuthenticated(true);
  };

  const logout = async () => {
    signOut(firebaseAuth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
    const fcmToken = await getMessagingToken();
    await logoutApi(fcmToken, subscriptionId, clientId);
    clearStorage();
    navigate(0);
  };

  const signup = async (req: TReqRegister, token: string) => {
    await signupApi(req, token);
    setIsAuthenticated(true);
  };

  const verifyOTP = async (otp: string) => {
    const token = await window.confirmationResult
      .confirm(otp)
      .then(async (res: any) => {
        return res?.user?.stsTokenManager?.accessToken;
      });
    return token;
  };

  const checkNeedToLogin = (redirect = routes.SIGNUP) => {
    if (!isAuthenticated) {
      navigate(redirect, {
        state: { from: { pathname: location.pathname } },
      });
      return true;
    }
    return false;
  };

  return {
    sendOTP,
    logout,
    verifyOTP,
    verifyPhone,
    signup,
    login,
    checkNeedToLogin,
  };
}

export default useAuth;
